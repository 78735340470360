const axios = require('axios')

import getUri from "./index"
import { EventBus } from '@/utils'


export const getPageOrders = (value, start, end, page, filters, jwt) => {
  try {
    return axios.post(getUri(`api/orders/find?value=${value}&start=${start}&end=${end}&page=${page}`), filters, { headers: { Authorization: `Bearer: ${jwt}` } })
  } catch (error) {
    if (error.response){
      if(error.response.status == 401){
        EventBus.$emit('logout')
      }
      if(error.response.status == 500){
        EventBus.$emit('error')
      }
      else{
        EventBus.$emit('fail')
      }
    }
    else{
      EventBus.$emit('fail')
    }
    return error
  }
}